<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Servicios</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn v-if="this.$hasPermision('servicios.create')" 
                        dark 
                        color="#023145" 
                        width="140" 
                        class="float-right" 
                        @click="handleShowCreate">
                        Nuevo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pt-1">
                    <v-text-field
                        v-model="search_service"
                        label="Buscar"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                        @keydown="handleInputSearchByEnter">
                    </v-text-field>
                </v-col>
                <v-col cols="8" class="pt-1">
                    <v-btn class="w-btn-search" height="40" @click="handleSearch">
                        Buscar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            v-model="selectedRows"
                            :headers="headers"
                            :items="services"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            show-select
                            :single-select="true"
                            @item-selected="handleSelectItem"
                            :loading="loading"
                            :options.sync="options"
                            :server-items-length="paginated.total"
                            loading-text="Loading... Please wait"
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todos los servicios
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.name="{ item }">
                                <span class="font-weight-medium">{{ item.name }}</span>
                                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
                            </template>

                            <template v-slot:item.base_price="{item}">
                                <span>{{ item.base_price | currency('S/') }}</span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <div class="text-center">
            <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
                <v-sheet class="text-center" height="85px">
                    <div class="py-3">
                        <v-btn v-if="this.$hasPermision('servicios.edit')" 
                            class="mt-3" 
                            dark 
                            color="blue" 
                            outlined 
                            @click="handleEdit">
                            <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
                            Editar
                        </v-btn>
                        <v-btn v-if="show_btn_active && this.$hasPermision('servicios.change_status')" 
                            class="ml-4 mt-3" 
                            dark 
                            color="green" 
                            outlined 
                            @click="handleChangeStatus">
                            <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
                            Activar
                        </v-btn>
                        <v-btn v-if="!show_btn_active && this.$hasPermision('servicios.change_status')" 
                            class="ml-4 mt-3" 
                            dark 
                            color="amber" 
                            outlined 
                            @click="handleChangeStatus">
                            <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
                            Inactivar
                        </v-btn>
                        <v-btn v-if="this.$hasPermision('servicios.destroy')" 
                            class="ml-4 mt-3" 
                            dark 
                            color="red" 
                            outlined 
                            @click="handleDelete">
                            <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
                            Eliminar
                        </v-btn>
                    </div>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <create-update ref="createUpdate"></create-update>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ArrayTools from '../../../helpers/ArrayTools';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import CreateUpdate from '../../../components/services/CreateUpdate';

export default {
    name: 'Services',
    components: {
        CreateUpdate,
        OfficeLogin
    },
    watch: {
        options: {
            handler() {
                this.loading = true;
                console.log(this.options);
                this.perPage = this.options.itemsPerPage;
                this.pageSelected = this.options.page;
                const search = this.search_service;
                console.log(search);
                if (
                this.filtersPaginated.name != null ||
                this.filtersPaginated.sku != null ||
                this.filtersPaginated.barcode != null ||
                this.filtersPaginated.category_id != null ||
                this.filtersPaginated.brand_id != null ||
                this.filtersPaginated.unit_id != null ||
                this.filtersPaginated.office_id != null
                ) {
                this.getServices({
                    paginated: true,
                    page: this.pageSelected,
                    itemsPerPage: this.perPage,
                    name: this.filtersPaginated.name,
                    sku: this.filtersPaginated.sku,
                    barcode: this.filtersPaginated.barcode,
                    category_id: this.filtersPaginated.category_id,
                    brand_id: this.filtersPaginated.brand_id,
                    unit_id: this.filtersPaginated.unit_id,
                    office_id: this.filtersPaginated.office_id,
                })
                    .then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                } else {
                if (search != null && search.trim().length > 0) {
                    // this.perPage = -1
                    this.getServices({
                    paginated: true,
                    page: this.pageSelected,
                    itemsPerPage: this.perPage,
                    name: search,
                    })
                    .then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                } else {
                    this.getServices({
                    paginated: true,
                    page: this.pageSelected,
                    itemsPerPage: this.perPage,
                    })
                    .then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                }
                }
            },
            deep: true,
        },
    },
    data () {
        return {
            search_service: null,
            selectedRows: [],
            show_options_crud: false,
            show_btn_active: false,
            itemsBreadcrumbs: [
                {
                    text: 'Logística',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'NOMBRE', value: 'name' },
                { text: 'PRECIO', value: 'base_price' },
            ],
            loading: false,
            dataTable: { page: 1 },
            options: {},
            pageSelected: 1,
            perPage: 10,
        }
    },
    computed: {
        ...mapState('services', [
            'services',
            "paginated",
            "filtersPaginated",
        ])
    },
    mounted() {
        this.changeFilterPaginated({
        name: null,
        company_id: null,
        });
    },
    methods: {
        ...mapActions('services', [
            'emptyServices',
            'getServices',
            'delete',
            'change_status',
            'changeFilterPaginated'
        ]),
        async onPageChange(page) {
            this.loading = true;
            await this.getServices({ page: page, paginated: true })
                .then((result) => (this.loading = false))
                .catch((errr) => (this.loading = false));
        },
        showOptionCrud() {
            /*this.show_options_crud = this.selectedRows.length > 0
            if (this.show_options_crud) {
                this.show_btn_active = !this.selectedRows[0].active
            }*/
            this.show_options_crud = this.selectedRows.length > 0;
            if (this.show_options_crud) {
                //let item = item.offices && item.offices.length == 1 && item.offices[0].active
                let item = this.selectedRows[0];
                if (item.offices && item.offices.length == 1) {
                this.show_btn_active = !item.offices[0].active;
                }
                //this.show_btn_active = !this.selectedRows[0].active
            }
        },
        handleSelectItem(selected) {
            if (selected.value) {
                this.selectedRows.push(selected.item)
            } else {
                let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
                if (index_found > -1) {
                    this.selectedRows.splice(index_found, 1)
                }
            }                     
            this.showOptionCrud()
        },
        handleInputSearchByEnter(KeyboardEvent){
            if (KeyboardEvent.code == 'Enter') {
                this.handleSearch();
            }
        },
        async handleSearch() {
            this.selectedRows = [];
            this.changeFilterPaginated({
                name: null,
                sku: null,
                barcode: null,
                category_id: null,
                brand_id: null,
                unit_id: null,
                office_id: null,
            });
            this.showOptionCrud();
            this.loading = true;
            let filters = {};
            this.dataTable.page = 1;
            if (this.search_product.trim().length > 0) {
                filters = {
                name: this.search_product,
                office_id: this.office_id,
                };
            } else {
                filters = {
                paginated: true,
                };
            }
            /*let filters = {
                name: this.search_service
            }*/
            await this.getServices(filters)
                .then((result) => (this.loading = false))
                .catch((errr) => (this.loading = false));
        },
        handleShowCreate () {
            this.$refs.createUpdate.showForm('REGISTRAR SERVICIO', 'create');
        },
        handleEdit() {
            let service = this.selectedRows[0];
            this.selectedRows = [];
            this.showOptionCrud();
            this.$refs.createUpdate.showForm('MODIFICAR DATOS DE SERVICIO', 'update', service);
        },
        async handleChangeStatus() {
            let service = this.selectedRows[0];
            this.selectedRows = [];
            this.showOptionCrud();
            await this.change_status(service);
        },
        async handleDelete() {
            let service = this.selectedRows[0];
            await this.delete(service);
            this.selectedRows = [];
            this.showOptionCrud();
        },
        async load () {
            this.loading = true;
            //await this.getServices();
            let filters = {
                office_id: this.office_id,
                paginated: true,
            };
        }
    },
    created () {
        this.load();
    },
    destroyed () {
        this.emptyServices();
    }
}
</script>

<style scoped>

</style>